import React from 'react';
import {MDBAnimation} from 'mdbreact';

export default ({white = false}) => {
  return (
    <>
      <section className="numbers-section-home py-5">
        <div className="container-fluid main-padding py-2">
          <div className=" justify-content-center align-items-center">
            <div className="col-12 p-0">
              <div className="col-md-auto"> </div>
              <div className="homepage-heading fadeInUp">Our journey since 2008</div>

              <div className="numbers py-5 h-100">
                <MDBAnimation type="fadeInUp" duration="0s" reveal>
                  <div className="journey-card pt-4 pb-2 px-3">
                    <div className="journey-card pl-2">
                      <img
                        className="home-journey__stat-img pt-3"
                        src={
                          require('../../assets/images/home-page/graphs/journey/leadership-icon-2.svg')
                            .default
                        }
                        alt="fortune star"
                      />
                      <div className="journey-description pt-2">
                        Stanford &amp;
                        <br />
                        Fortune 10 trained
                        <br />
                        leadership
                      </div>
                    </div>
                  </div>
                </MDBAnimation>

                <MDBAnimation type="fadeInUp" duration="0s" reveal>
                  <div className="journey-card pt-4 pb-2 px-3">
                    <div className="journey-card pl-2">
                      <img
                        className="home-journey__stat-img"
                        src={
                          require('../../assets/images/home-page/graphs/journey/projects-delivered-icon-2.svg')
                            .default
                        }
                        alt="projects delivered"
                      />
                      <div className="journey-number ">500+</div>
                      <div className="journey-description ">Projects delivered</div>
                    </div>
                  </div>
                </MDBAnimation>

                <MDBAnimation type="fadeInUp" duration="0s" reveal>
                  <div className=" journey-card pt-4 pb-2 px-3">
                    <div className="journey-card pl-2">
                      <img
                        className="home-journey__stat-img"
                        src={
                          require('../../assets/images/home-page/graphs/journey/team-icon-2.svg')
                            .default
                        }
                        alt="strong team"
                      />
                      <div className="journey-number ">200+</div>
                      <div className="journey-description ">Strong team</div>
                    </div>
                  </div>
                </MDBAnimation>

                <MDBAnimation type="fadeInUp" duration="0s" reveal>
                  <div className=" journey-card pt-4 pb-2 px-3">
                    <div className="journey-card pl-2">
                      <img
                        className="home-journey__stat-img"
                        src={
                          require('../../assets/images/home-page/graphs/journey/locations-icon-2.svg')
                            .default
                        }
                        alt="fortune star"
                      />
                      <div className="journey-number ">06</div>
                      <div className="journey-description ">Countries</div>
                    </div>
                  </div>
                </MDBAnimation>

                <MDBAnimation type="fadeInUp" duration="0s" reveal>
                  <div className=" journey-card pt-4 pb-2 px-3">
                    <div className="journey-card pl-2">
                      <img
                        className="home-journey__stat-img"
                        src={
                          require('../../assets/images/home-page/graphs/journey/unicorns-icon-2.svg')
                            .default
                        }
                        alt="unicorn"
                      />
                      <div className="journey-number ">10</div>
                      <div className="journey-description ">Unicorns</div>
                    </div>
                  </div>
                </MDBAnimation>
                <MDBAnimation type="fadeInUp" duration="0s" reveal>
                  <div className="journey-card pt-4 pb-2 px-3">
                    <div className="journey-card pl-2">
                      <img
                        className="home-journey__stat-img"
                        src={
                          require('../../assets/images/home-page/graphs/journey/fortune-icon-2.svg')
                            .default
                        }
                        alt="fortune star"
                      />
                      <div className="journey-number ">03</div>
                      <div className="journey-description ">Fortune 500</div>
                    </div>
                  </div>
                </MDBAnimation>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
