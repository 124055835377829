import React, {useState} from 'react';
import Client from './ClientLogoCard';
import * as classes from './index.module.css';

function ClientLogos(props) {
  const {expandable = true} = props;
  const [viewAll, setViewAll] = useState(false);
  const [closingAnimation, setClosingAnimation] = useState(false);

  const handleChange = () => {
    if (!viewAll) {
      setViewAll(true);
    } else {
      setClosingAnimation(true);
      setTimeout(() => {
        setClosingAnimation(false);
        setViewAll(false);
      }, 0);
    }
  };

  return (
    <section className="client-logo-section py-5">
      <div className="container-fluid main-padding">
        <div className="homepage-heading d-none d-sm-none d-md-block">
          Trusted by 160 Startups, 10 Unicorns, and 3 Fortune 500s
        </div>
        <div className="homepage-heading d-block d-sm-block d-md-none px-3">
          Trusted by 160 Startups, 10 Unicorns, and 3 Fortune 500s
        </div>
        <div className="d-flex justify-content-center align-items-center mt-5">
          <div className="col-12">
            <div className={`row ${!viewAll ? classes.hide : ''}`}>
              <Client image="xverse.webp" alt="xverse" />

              <Client image="bedbathbeyond.webp" alt="bedbathbeyond" />
              <Client image="careem.webp" alt="careem" />
              <Client image="drafkings.webp" alt="drafkings" />
              <Client image="stacks.webp" alt="stacks" />
              <Client image="lifeprint.webp" alt="lifeprint" />
              <Client image="chillingo.webp" alt="chillingo" />
              <Client image="decorist.webp" alt="decorist" />
              <Client image="one-kings-lane.webp" alt="onekingslane-ny" />
              <Client image="embrwave.webp" alt="embrwave" />
              <Client image="raven.webp" alt="raven" />
              <Client image="bbc-worldwide.webp" alt="bbc-worldwide" />

              {viewAll && (
                <div className="row mw-100 ml-auto mr-auto">
                  <Client image="playdate-digital.webp" alt="playdate-digital" />
                  <Client image="houghton-mifflin-harcourt.webp" alt="houghton-mifflin-harcourt" />
                  <Client image="beatbugs.webp" alt="beatbugs" />

                  <Client image="joyful-works.webp" alt="joyful-works" />
                  <Client image="haiku-games.webp" alt="haiku-games" />
                  <Client image="pocket-gems.webp" alt="pocket-gems" />
                  <Client image="digital-chocolate.webp" alt="digital-chocolate" />
                  <Client image="redbird-advanced-learning.webp" alt="redbird-advanced-learning" />
                  <Client image="pixowl.webp" alt="pixowl" />
                  <Client image="syncthink.webp" alt="syncthink" />
                  <Client image="alkanza.webp" alt="alkanza" />
                  <Client image="ander.webp" alt="ander" />
                  <Client image="empowher.webp" alt="empowher" />
                  <Client image="lingoquest.webp" alt="lingoquest" />
                  <Client image="forfit.webp" alt="forfit" />
                  <Client image="chronos.webp" alt="chronos" />
                  <Client image="charadicon.webp" alt="charadicon" />
                  <Client image="talespin.webp" alt="talespin" />
                  <Client image="stick-sports.webp" alt="sticksports" />
                  <Client image="common-sense-media.webp" alt="common-sense-media" />
                </div>
              )}
            </div>

            {expandable && (
              <div className="text-center">
                <button onClick={() => handleChange()} className={classes.button} tabIndex="-1">
                  {!viewAll ? 'View All' : 'Close'}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ClientLogos;
